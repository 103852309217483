import { Box, Button } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../store/slices/authSlice'
import { isTokenExpired } from '../utils/checkAuthorization'
import { supabase } from '../utils/supabase'
import CheckboxInput from './ui-elements/CheckboxInput'
import ColorInput from './ui-elements/ColorInput'
import FileInput from './ui-elements/FileInput'
import TextInput from './ui-elements/TextInput'
import { v4 as uuidv4 } from 'uuid';
import { useParams } from 'react-router-dom'
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


const Form = ({
    fields,
    schema,
    setCurrentStep,
    currentStep,
    setFormValues,
    setLogoPreview,
    isLastStep,
    formValues,
    formId
}) => {
    const params = useParams()

    console.log({ params: params })
    const [openDeleteToastSuccess, setOpenDeleteToastSuccess] = useState(false);
    const [openDeleteToastFailed, setOpenDeleteToastFaied] = useState(false);
    const handleOpenSuccessToast = () => {
        setOpenDeleteToastSuccess(true);
    };

    const handleCloseSuccessToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDeleteToastSuccess(false);
    };
    const handleOpenFailedToast = () => {
        setOpenDeleteToastFaied(true);
    };

    const handleCloseFailedToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDeleteToastFaied(false);
    };

    // console.log({ formValues })

    // useEffect(() => {
    //     supabase.from('ad-logos').select('/images/Binance_Logo.png').then((res) => console.log({ res }))
    //     // console.log({ error, data })
    // }, [])

    const dispatch = useDispatch()
    const { userData } = useSelector((state) => state?.auth)
    const [file, setFile] = useState(null)
    const {
        handleSubmit,
        values,
        errors,
        setFieldValue,
        touched
    } = useFormik({
        validationSchema: schema,
        initialValues: {
            ...formValues,
            button_color: '#000000',
            button_text_color: "#ffffff",
        },
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: async (formVals) => {
            console.log({ formVals })
            setFormValues({ ...formVals })
            // console.log({ isLastStep })
            if (isLastStep) {

                if (formVals.logo.name) {
                    const { data, error: uploadError } = await supabase
                        .storage
                        .from('ad-logos') // Replace 'images' with your bucket name
                        .upload(`images/${uuidv4()}-${formVals.logo.name}`, formVals['logo'], {
                            // contentType: formVals.logo.type,
                            // cacheControl: '3600',
                            upsert: true
                        }
                        ).then(async (resp) => {
                            const dbData = await supabase.from('ads').insert({
                                ...formVals,
                                id: uuidv4(),
                                logo: `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/ad-logos/${resp.data.path}`
                            }).then((response) => {
                                handleOpenSuccessToast()
                                console.log({ response })
                            }).catch((err) => {
                                handleOpenFailedToast()
                            })
                            console.log({ dbData })
                        });

                    if (uploadError) {
                        handleOpenFailedToast()
                        throw uploadError;
                    }

                    const imageUrl = data.publicUrl;
                    console.log({ data, path: data.path, imageUrl });
                }
                else {
                    const dbData = await supabase.from('ads').update({
                        ...formVals,
                    }).eq('id', params.id).then((response) => {
                        console.log({ response })
                    }).catch((err) => {
                        handleOpenFailedToast()
                    })
                    console.log({ dbData })

                }
            }
            else {
                setFile(formVals['logo'])
                setCurrentStep((ps) => ps + 1)
            }
        }
    })
    console.log({ values, errors, isLastStep })
    return (
        <>
            <form onSubmit={handleSubmit}>
                {fields?.map(({ label, placeholder, type, name }) => {
                    if (type === 'checkbox') {
                        return <CheckboxInput
                            setFormValues={setFormValues}
                            label={label}
                            value={values[name]}
                            name={name}
                            setFieldValue={setFieldValue}
                            values={values}
                        />
                    }
                    else if (type === 'color') {
                        return <ColorInput
                            setFormValues={setFormValues}
                            label={label}
                            value={values[name]}
                            name={name}
                            setFieldValue={setFieldValue}
                        />
                    }
                    else if (type === 'file') {
                        return <FileInput
                            setFormValues={setFormValues}
                            label={label}
                            value={values[name]}
                            name={name}
                            setFieldValue={setFieldValue}
                            setLogoPreview={setLogoPreview}
                            setFile={setFile}
                        />
                    }
                    return (
                        <TextInput
                            setFormValues={setFormValues}
                            label={label}
                            placeholder={placeholder}
                            value={values[name]}
                            error={errors[name]}
                            name={name}
                            setFieldValue={setFieldValue}
                        />
                    )
                })}
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Button sx={{ mt: '20px', width: '150px' }} disabled={currentStep === 1} onClick={() => setCurrentStep(ps => ps - 1)} variant='contained'>Back</Button>
                    <Button sx={{ mt: '20px', width: '150px' }} type='submit' variant='contained'>{isLastStep ? 'Update' : 'Next'}</Button>
                </Box>
            </form>
            <Snackbar open={openDeleteToastSuccess} autoHideDuration={6000} onClose={handleCloseSuccessToast}>
                <Alert
                    onClose={handleCloseSuccessToast}
                    severitFailed="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Item updated successfully
                </Alert>
            </Snackbar>
            <Snackbar open={openDeleteToastFailed} autoHideDuration={6000} onClose={handleCloseFailedToast}>
                <Alert
                    onClose={handleCloseFailedToast}
                    severitFailed="error"
                    variant="filled"
                    sx={{ width: '100%', bgcolor: 'red' }}
                >
                    Failed to update item
                </Alert>
            </Snackbar>
        </>
    )
}

export default Form