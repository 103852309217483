import { Box, Input } from '@mui/material'
import React from 'react'

const ColorInput = ({
    label,
    name,
    setFieldValue,
    value,
    setFormValues
}) => {
    const handleChange = (e) => {
        setFieldValue(name, e.target.value)
        setFormValues([name], e.target.value)
    }
    return (
        <Box sx={{ mt: '20px' }}>
            <label>{label}</label>
            <Input type="color" sx={{
                width: '100%',
                border: '1px solid #ccc',
                padding: '10px',
                borderRadius: '5px',
                mt: '5px',
                '&:hover': {
                    '&::before': {
                        borderBottom: 'none !important',
                    }
                },
                '&Mui-focused': {
                    outline: 'none',
                },
                '&::before': {
                    borderBottom: 'none'
                }
            }}
                value={value}
                onChange={handleChange}
            />
        </Box>
    )
}

export default ColorInput