import { Box, Checkbox } from '@mui/material'
import React, { useEffect } from 'react'

const CheckboxInput = ({
    label,
    name,
    setFieldValue,
    value,
    setFormValues,
    values
}) => {
    const handleChange = (e) => {
        const { checked } = e.target
        console.log({ e, checked })
        setFieldValue(name, checked)
        setFormValues([name], checked)
    }
    useEffect(() => {
        setFieldValue(name, value)
        setFormValues([name], value)
    }, [values])

    // console.log({ value, values })
    return (
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
            {/* <Checkbox id={name} onChange={handleChange} sx={{ paddingLeft: 0 }} name={name} checked={value} /> */}
            <input type='checkbox' checked={value} id={name} onChange={handleChange} className='checkbox-input' />
            <label htmlFor={name}>{label}</label>
        </Box>
    )
}

export default CheckboxInput