import { Box, Button, Input, Typography } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { logout } from '../store/slices/authSlice'
import { isTokenExpired } from '../utils/checkAuthorization'
import { supabase } from '../utils/supabase'
import CheckboxInput from './ui-elements/CheckboxInput'
import ColorInput from './ui-elements/ColorInput'
import FileInput from './ui-elements/FileInput'
import TextInput from './ui-elements/TextInput'
import { v4 as uuidv4 } from 'uuid';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { setBrandsFields } from '../store/slices/brandsFromStepSlice'
import { FaTrash } from 'react-icons/fa6'
import LogoInput from './ui-elements/LogoInput'


const Form = ({
    fields,
    schema,
    setCurrentStep,
    currentStep,
    setFormValues,
    setLogoPreview,
    isLastStep,
    formValues,
    setActiveBrand
}) => {
    const dispatch = useDispatch()
    const { userData } = useSelector((state) => state?.auth)
    const { brandsFields } = useSelector((state) => state.brandsFields)
    const [openDeleteToastSuccess, setOpenDeleteToastSuccess] = useState(false);
    const [openDeleteToastFailed, setOpenDeleteToastFaied] = useState(false);
    const handleOpenSuccessToast = () => {
        setOpenDeleteToastSuccess(true);
    };


    // const [brandsFields, setBrandsFields] = useState([])
    // useEffect(() => {
    //     if (currentStep === 2) {
    //         // setBrandsFields(fields)
    //         // dispatch(setBrandsFields(fields))
    //     }

    // }, [])


    const handleCloseSuccessToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDeleteToastSuccess(false);
    };
    const handleOpenFailedToast = () => {
        setOpenDeleteToastFaied(true);
    };

    const handleCloseFailedToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDeleteToastFaied(false);
    };
    // console.log({ formValues })

    // useEffect(() => {
    //     supabase.from('ad-logos').select('/images/Binance_Logo.png').then((res) => console.log({ res }))
    //     // console.log({ error, data })
    // }, [])


    const [file, setFile] = useState(null)

    // const uploadImage = async (item) => {
    //     console.log({ item })
    //     let path
    //     await supabase
    //         .storage
    //         .from('brands') // Replace 'images' with your bucket name
    //         .upload(`images/${uuidv4()}-${item.
    //             brand_logo
    //             .name}`, item?.
    //             brand_logo
    //             , {
    //                 // contentType: formVals.logo.type,
    //                 // cacheControl: '3600',
    //                 upsert: true
    //             }
    //         ).then(async (resp) => {

    //             path = resp.data.path
    //             // return resp.data.path
    //             // const dbData = await supabase.from('ads').insert({
    //             //     ...formVals,
    //             //     id: uuidv4(),
    //             //     logo: `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/ad-logos/${resp.data.path}`
    //             // }).then((response) => {
    //             //     handleOpenSuccessToast()
    //             //     setCurrentStep(1)
    //             //     setFormValues(null)
    //             //     setValues({})
    //             //     resetForm()
    //             // })
    //         });
    //     console.log({ path })
    //     return path
    // }
    const uploadImage = async (item) => {
        // console.log('Uploading item:', item);
        // Check if brand_logo is a valid file
        if (!item?.brand_logo) {
            console.error('No brand_logo found:', item);
            return null;
        }

        try {
            const { data, error } = await supabase.storage
                .from('brands') // Replace 'brands' with your bucket name
                .upload(`images/${uuidv4()}-${item.brand_logo.name}`, item.brand_logo, {
                    upsert: true
                });

            if (error) {
                console.error('Error uploading file:', error.message);
                return null;
            }

            // console.log('Uploaded file path:', data.path);
            return data.path; // Return the path of the uploaded image
        } catch (error) {
            console.error('Unexpected error:', error.message);
            return null;
        }
    };

    const {
        handleSubmit,
        values,
        errors,
        setFieldValue,
        touched,
        resetForm,
        setValues
    } = useFormik({
        validationSchema: schema,
        initialValues: {
            ...formValues,
            button_color: '#000000',
            button_text_color: "#ffffff",
            brands: []
        },
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: async (formVals) => {
            // console.log({ formVals })
            // setFormValues(null, { ...formVals })
            // console.log({ isLastStep })
            if (isLastStep) {
                if (isTokenExpired(userData)) {
                    supabase.auth.signOut().then(() => {
                        dispatch(logout())
                    })
                }
                else {
                    console.log({ formVals, formValues })
                    const brandsLogos = []
                    const uploadedImages = formValues.brands.map((item) => {
                        uploadImage(item).then((res) => {
                            // console.log({ res, item })
                            brandsLogos.push({
                                brand_link: item?.brand_link,
                                brand_title: item?.brand_title,
                                brand_id: item?.id,
                                brand_logo_link: res,
                            })
                            if (brandsLogos.length === formValues.brands.length) {
                                supabase.from('ads').insert({
                                    ...formVals,
                                    brands: brandsLogos,
                                    id: uuidv4(),
                                }).then(result => {
                                    console.log({ result })
                                    handleOpenSuccessToast()
                                    // setCurrentStep(1)
                                    // setFormValues(null)
                                    // setValues({})
                                    // resetForm()
                                })
                            }
                            return res
                        }).catch((err) => {
                            console.log({ err })
                        });
                    })
                    console.log({ uploadedImages, brandsLogos })

                    // if (brandsLogos.length === formValues.brands.length) {
                    //     await supabase.from('ads').insert({
                    //         ...formVals,
                    //         brands: brandsLogos,
                    //         id: uuidv4(),
                    //     }).then(result => {
                    //         console.log({ result })
                    //         handleOpenSuccessToast()
                    //         // setCurrentStep(1)
                    //         // setFormValues(null)
                    //         // setValues({})
                    //         // resetForm()
                    //     })
                    // }

                    // const uploadedImages = await Promise.all(
                    //     formVals.brands.map(item => uploadImage(item))
                    // );

                    // console.log('Uploaded Images:', uploadedImages);


                    // const { data, error: uploadError } = await supabase
                    //     .storage
                    //     .from('ad-logos') // Replace 'images' with your bucket name
                    //     .upload(`images/${uuidv4()}-${formVals.logo.name}`, formVals['logo'], {
                    //         // contentType: formVals.logo.type,
                    //         // cacheControl: '3600',
                    //         upsert: true
                    //     }
                    //     ).then(async (resp) => {
                    //         const dbData = await supabase.from('ads').insert({
                    //             ...formVals,
                    //             id: uuidv4(),
                    //             logo: `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/ad-logos/${resp.data.path}`
                    //         }).then((response) => {
                    //             handleOpenSuccessToast()
                    //             setCurrentStep(1)
                    //             setFormValues(null)
                    //             setValues({})
                    //             resetForm()
                    //         })
                    //     });

                    // if (uploadError) {
                    //     handleOpenFailedToast()
                    //     throw uploadError;
                    // }

                    // const imageUrl = data.publicUrl;
                    // console.log({ data, path: data.path, imageUrl });
                }
            }
            else {
                // setFile(formVals['logo'])
                setCurrentStep((ps) => ps + 1)
            }
        }
    })

    const isValidPrevBrands = (array) => {
        for (const obj of array) {
            // Check if any property of the object is null or an empty string
            if (!obj.id || !obj.brand_title || !obj.brand_link || !obj.brand_logo) {
                return false;
            }
        }
        return true;
    }

    const handleAddRow = () => {
        // console.log({ brands: values['brands'] })
        if (isValidPrevBrands(values['brands'])) {
            const newBrand = {
                id: uuidv4(),
                brand_title: '',
                brand_logo: null,
                brand_logo_preview: null,
                brand_link: '',
            }
            setFieldValue('brands', [
                ...values?.['brands'],
                newBrand
            ])
            setActiveBrand(newBrand.id)
            console.log({ formValues })
            const tempBrands = !!formValues?.brands?.length ? [...formValues?.brands] : []
            tempBrands.push(newBrand)

            setFormValues('brands', tempBrands)

        }
        else {
            alert('Please insert all values in previous brands')
        }
    }
    const handleUpdate = (e, id) => {
        const { value, name } = e.target
        console.log({ formValues, values })
        const tempBrands = [...formValues?.['brands']]
        const currentBrandIndex = tempBrands.findIndex((el) => el.id === id)
        tempBrands[currentBrandIndex] = {
            ...tempBrands[currentBrandIndex],
            [name]: value
        }
        setFieldValue('brands', tempBrands)
        setFormValues('brands', tempBrands)
    }

    const handleDeleteBrand = (id) => {
        const tempBrands = [...values['brands']].filter((item) => item.id !== id)
        setFieldValue('brands', tempBrands)
    }
    // console.log({ values, formValues })
    return (
        <>
            <form onSubmit={handleSubmit}>
                {
                    currentStep === 2 ? brandsFields.map(({ formFields }, index) => {
                        return <>
                            <Box>
                                <Box display='flex'>
                                    {
                                        formFields.map((field, i) => {
                                            return <Typography flex={1} sx={{
                                                border: 'solid 1px #ccc',
                                                p: '5px'
                                            }} key={i}>{field.label}</Typography>
                                        })
                                    }
                                    <Typography flex={1} sx={{
                                        border: 'solid 1px #ccc',
                                        p: '5px'
                                    }}>Action</Typography>
                                </Box>
                                <Box>
                                    {
                                        !!formValues?.['brands']?.length && formValues?.['brands'].map((item, index) => {
                                            return <Box display={'flex'}>
                                                {/* <Box flex={1} sx={{
                                                    border: 'solid 1px #ccc',
                                                    p: '5px'
                                                }}
                                                    display={'flex'} justifyContent="center" alignItems={"center"}
                                                >
                                                    <label htmlFor={item.id}>Insert Logo</label>
                                                    <Input
                                                        sx={{
                                                            display: 'none'
                                                        }}
                                                        id={item.id}
                                                        type="file"
                                                    />
                                                </Box> */}
                                                <LogoInput formValues={formValues} item={item} setFormValues={setFormValues} />
                                                <Box flex={1} sx={{
                                                    border: 'solid 1px #ccc',
                                                    p: '5px',
                                                    display: 'flex',
                                                    alignItems: 'end'
                                                }}>
                                                    <Input sx={{
                                                        border: 'none',
                                                        width: '100%',
                                                    }} value={item?.brand_title} name={'brand_title'} onChange={(e) => handleUpdate(e, item.id)} />
                                                </Box>
                                                <Box flex={1} sx={{
                                                    border: 'solid 1px #ccc',
                                                    p: '5px'
                                                }}>
                                                    <Input sx={{
                                                        border: 'none',
                                                        width: '100%'
                                                    }} value={item?.brand_link} name={'brand_link'} onChange={(e) => handleUpdate(e, item.id)} />
                                                </Box>
                                                <Box display={'flex'} justifyContent="center" alignItems={"center"} flex={1} sx={{
                                                    border: 'solid 1px #ccc',
                                                    p: '5px'
                                                }}>
                                                    <FaTrash onClick={() => handleDeleteBrand(item.id)} />
                                                </Box>
                                            </Box>
                                        })
                                    }
                                </Box>
                            </Box>
                            <Button sx={{ mt: '20px', width: '100%' }} disabled={1 === 2} onClick={handleAddRow} variant='contained'>Add brand</Button>
                        </>
                    })
                        : fields?.map(({ label, placeholder, type, name }) => {
                            if (type === 'checkbox') {
                                return <CheckboxInput
                                    setFormValues={setFormValues}
                                    label={label}
                                    value={values[name]}
                                    name={name}
                                    setFieldValue={setFieldValue}
                                    values={values}
                                />
                            }
                            else if (type === 'color') {
                                return <ColorInput
                                    setFormValues={setFormValues}
                                    label={label}
                                    value={values[name]}
                                    name={name}
                                    setFieldValue={setFieldValue}
                                />
                            }
                            else if (type === 'file') {
                                return <FileInput
                                    setFormValues={setFormValues}
                                    label={label}
                                    value={values[name]}
                                    name={name}
                                    setFieldValue={setFieldValue}
                                    setLogoPreview={setLogoPreview}
                                    setFile={setFile}
                                />
                            }
                            return (
                                <TextInput
                                    setFormValues={setFormValues}
                                    label={label}
                                    placeholder={placeholder}
                                    value={values[name]}
                                    error={errors[name]}
                                    name={name}
                                    setFieldValue={setFieldValue}
                                />
                            )
                        })
                }
                {/* {fields?.map(({ label, placeholder, type, name }) => {
                    if (type === 'checkbox') {
                        return <CheckboxInput
                            setFormValues={setFormValues}
                            label={label}
                            value={values[name]}
                            name={name}
                            setFieldValue={setFieldValue}
                            values={values}
                        />
                    }
                    else if (type === 'color') {
                        return <ColorInput
                            setFormValues={setFormValues}
                            label={label}
                            value={values[name]}
                            name={name}
                            setFieldValue={setFieldValue}
                        />
                    }
                    else if (type === 'file') {
                        return <FileInput
                            setFormValues={setFormValues}
                            label={label}
                            value={values[name]}
                            name={name}
                            setFieldValue={setFieldValue}
                            setLogoPreview={setLogoPreview}
                            setFile={setFile}
                        />
                    }
                    return (
                        <TextInput
                            setFormValues={setFormValues}
                            label={label}
                            placeholder={placeholder}
                            value={values[name]}
                            error={errors[name]}
                            name={name}
                            setFieldValue={setFieldValue}
                        />
                    )
                })} */}
                <Box display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                    <Button sx={{ mt: '20px', width: '150px' }} disabled={currentStep === 1} onClick={() => setCurrentStep(ps => ps - 1)} variant='contained'>Back</Button>
                    <Button sx={{ mt: '20px', width: '150px' }} type='submit' variant='contained'>{isLastStep ? 'Submit' : 'Next'}</Button>
                </Box>
            </form>
            <Snackbar open={openDeleteToastSuccess} autoHideDuration={6000} onClose={handleCloseSuccessToast}>
                <Alert
                    onClose={handleCloseSuccessToast}
                    severitFailed="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Item created successfully
                </Alert>
            </Snackbar>
            <Snackbar open={openDeleteToastFailed} autoHideDuration={6000} onClose={handleCloseFailedToast}>
                <Alert
                    onClose={handleCloseFailedToast}
                    severitFailed="error"
                    variant="filled"
                    sx={{ width: '100%', bgcolor: 'red' }}
                >
                    Failed to create item
                </Alert>
            </Snackbar>
        </>
    )
}

export default Form