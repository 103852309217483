import { Box, Button, Container } from '@mui/material'
import { useFormik } from 'formik'
import React, { useEffect, useState } from 'react'
// import Form from '../components/Form'
import TextInput from '../components/ui-elements/TextInput'
import { login_form, login_signup_form_with_email_and_pass } from '../utils/form'
import { login_schema } from '../utils/schema'
import { supabase } from '../utils/supabase'
import { useDispatch } from 'react-redux'
import { login } from '../store/slices/authSlice'

const LoginPage = () => {
    const dispatch = useDispatch()
    // console.log({
    //     env: process.env,
    //     url: process.env.REACT_APP_SUPABASE_URL,
    //     key: process.env.REACT_APP_SUPABASE_KEY
    // })
    const [formValues, setFormValues] = useState(null)
    const [isOTPSent, setIsOTPSent] = useState(false)
    const {
        handleSubmit,
        values,
        errors,
        setFieldValue,
        touched
    } = useFormik({
        validationSchema: login_schema,
        initialValues: {},
        validateOnChange: false,
        validateOnBlur: false,
        enableReinitialize: true,
        onSubmit: async (formVals) => {
            console.log({ formVals });
            const {
                data: { session },
                error,
            } = await supabase.auth.verifyOtp({
                phone: formVals['phone_number'],
                token: formVals['otp'],
                type: 'sms',
            })
            dispatch(login(session))
        }
    })
    const handleSendOtp = async (phone) => {
        await supabase.auth.signInWithOtp({
            phone
        }).then(() => {
            setIsOTPSent(true)
        })

    }
    useEffect(() => {
        const { data, error } = supabase.from('ads').select('*').then((rs) => {
            console.log({ rs })
            // dispatch(getCardsData(rs.data))
        })
        console.log({ data, error })
    }, [])
    return (
        <Container sx={{ my: '20px' }} maxWidth="lg">
            Login
            <form onSubmit={handleSubmit}>

                {
                    login_form?.map(({ label, placeholder, name }, index) => {
                        if (index === 0) {
                            return (
                                <Box
                                    display={'flex'}
                                    alignItems="end"
                                >
                                    <TextInput
                                        setFormValues={setFormValues}
                                        label={label}
                                        placeholder={placeholder}
                                        value={values[name]}
                                        error={errors[name]}
                                        name={name}
                                        setFieldValue={setFieldValue}
                                    />
                                    <Button onClick={() => handleSendOtp(values['phone_number'])} sx={{
                                        minWidth: '120px',
                                        ml: '20px'
                                    }} variant='contained'>Send OTP</Button>
                                </Box>)
                        }
                        else {
                            return (
                                <TextInput
                                    setFormValues={setFormValues}
                                    label={label}
                                    placeholder={placeholder}
                                    value={values[name]}
                                    error={errors[name]}
                                    name={name}
                                    setFieldValue={setFieldValue}
                                />
                            )
                        }
                    })
                }
                {/* {
                    login_signup_form_with_email_and_pass?.map(({ label, placeholder, name }, index) => {

                        return (
                            <Box
                                display={'flex'}
                                alignItems="end"
                            >
                                <TextInput
                                    setFormValues={setFormValues}
                                    label={label}
                                    placeholder={placeholder}
                                    value={values[name]}
                                    error={errors[name]}
                                    name={name}
                                    setFieldValue={setFieldValue}
                                />
                            </Box>
                        );
                    })} */}
                <Button sx={{
                    mt: '40px'
                }} type="submit" variant='contained'>Submit</Button>
            </form>
        </Container>
    )
}

export default LoginPage