import { createSlice } from '@reduxjs/toolkit';

const dataSlice = createSlice({
    name: 'dataSlice',
    initialState: {
        cards: [],
    },
    reducers: {
        getCardsData: (state, action) => {
            state.cards = action.payload;
        },
        clearCards: (state) => {
            state.cards = [];
        },
        deleteCard: (state, action) => {
            state.cards = state.cards.filter((item) => item.id !== action.payload)
        }
    },
});

export const { getCardsData, clearCards, deleteCard } =
    dataSlice.actions;
export default dataSlice.reducer;
