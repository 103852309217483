import { createSlice } from '@reduxjs/toolkit';

const brandsFromStepSlice = createSlice({
    name: 'brandsFromStepSlice',
    initialState: {
        brandsFields: [
            {
                id: 1,
                formFields: [
                    {
                        label: 'Logo',
                        type: 'file',
                        name: 'brand_logo'
                    },
                    {
                        label: 'Brand title',
                        placeholder: 'Enter brand title',
                        type: 'text',
                        name: 'brand_title'
                    },
                    {
                        label: 'Brand Link',
                        placeholder: 'Enter brand url',
                        type: 'text',
                        name: 'brand_link'
                    },
                ]
            }
        ],
    },
    reducers: {
        setBrandsFields: (state, action) => {
            state.brandsFields = [
                ...state.brandsFields,
                ...action.payload
            ];
        },
        clearBrandsField: (state, action) => {
            state.brandsFields = state.brandsFields.filter((el) => el.id === action.payload.id);
        },
    },
});

export const { setBrandsFields, clearBrandsField } =
    brandsFromStepSlice.actions;
export default brandsFromStepSlice.reducer;
