export const unit_preview_outer_wrapper_style = {
    border: 'solid 1px #ccc',
    borderRadius: '5px',
    padding: '20px 0'
}
export const unit_preview_inner_wrapper_style = {
    border: 'solid 1px #ccc',
    position: 'relative',
    display: 'inline-block',
    width: '100%',
}
export const sponsored_triangle_style = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 0,
    height: 0,
    borderLeft: '0px solid transparent',
    borderRight: '35px solid transparent',
    // borderBottom: '35px solid #800',
    transform: 'rotate(90deg)'
}
export const sponsored_triangle_text_style = {
    transform: 'rotate(-140deg)',
    position: 'absolute',
    bottom: '-32px',
    left: '4px',
}
export const title_box_style = {
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    p: '10px 0',
}
export const title_text_style = {
    px: '15px',
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 600,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%'
}
export const finder_award_outer_box_style = {
    minHeight: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}
export const finder_award_inner_box_style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: '3px 8px',
    borderRadius: '30px',
    background: 'linear-gradient(0deg, #eedb96, #eedb96), linear-gradient(92.16deg, rgba(255, 255, 255, .4) 0, rgba(255, 255, 255, 0) 100%), #e4c558'
}
export const logo_image_box_style = {
    minHeight: '180px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end'
}
export const brand_title_style = {
    textDecoration: 'none',
    minHeight: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
}
export const desc_box_style = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    p: '5px 10px',
    borderRadius: '20px',
    maxWidth: '80%',

}