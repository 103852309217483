import * as Yup from 'yup';

const fileSchema = Yup.mixed().test('fileType', 'Brand logo must be an image', (value) => {
    if (!value) return false; // if value is not present
    return value && (value instanceof File) && ['image/jpeg', 'image/png', 'image/gif'].includes(value.type);
}).required('Brand logo is required');

export const add_form_step_1_schema = Yup.object({
    title: Yup.string().required('Required'),
    title_background_color: Yup.string(),
    title_text_color: Yup.string(),
    sponsored: Yup.boolean(),
    spon_back_background_color: Yup.string(),
    finder_award: Yup.boolean(),
    finder_award_text: Yup.string(),
    finder_award_tooltip_text: Yup.string(),
    // logo: Yup.mixed(),
    // brand_title: Yup.string().required('Required'),
    // brand_link: Yup.string()
    //     .matches(
    //         /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
    //         'Enter correct url!'
    //     )
    //     .required('Please enter website'),

})
export const add_form_step_1_2_schema = Yup.object({
    brands: Yup.array().of(
        Yup.object().shape({
            brand_title: Yup.string().required('Brand title is required'),
            brand_logo: fileSchema,
            brand_link: Yup.string().url('Brand link must be a valid URL').required('Brand link is required'),
        })
    ).min(1, 'At least one brand is required').max(3, 'You can have a maximum of 3 brands')
})

export const add_form_step_2_schema = Yup.object({
    description: Yup.string().required('Required'),
    description_back_color: Yup.string(),
    description_text_color: Yup.string(),
    t_and_c_apply: Yup.boolean(),
    button_color: Yup.string(),
    button_text_color: Yup.string(),
    button_text: Yup.string(),
    read_review_link: Yup.string(),
    read_review_button_text: Yup.string(),
    read_review_button_text_color: Yup.string(),
});

export const login_schema = Yup.object({
    phone_number: Yup.string().required('Phone'),
    otp: Yup.string().required('Phone'),
})