import { add_form_step_1_2_schema, add_form_step_1_schema, add_form_step_2_schema, login_schema } from "./schema";

export const add_unit_form = [
    {
        schema: add_form_step_1_schema,
        fields: [
            {
                label: 'Title',
                placeholder: 'Enter title',
                type: 'text',
                name: 'title'
            },
            {
                label: 'Title background color',
                type: 'color',
                name: 'title_background_color'
            },
            {
                label: 'Title text color',
                type: 'color',
                name: 'title_text_color'
            },
            // {
            //     label: 'Sponsored',
            //     type: 'checkbox',
            //     name: 'sponsored'
            // },
            {
                label: 'Sponsored',
                type: 'checkbox',
                name: 'sponsored'
            },
            {
                label: 'Sponsored tag background color',
                type: 'color',
                name: 'spon_back_background_color'
            },
            {
                label: 'Finder Award',
                type: 'checkbox',
                name: 'finder_award'
            },
            {
                label: 'Finder award text',
                placeholder: 'Enter finder award text',
                type: 'text',
                name: 'finder_award_text'
            },
            {
                label: 'Finder award tooltip text',
                placeholder: 'Enter finder award tooltip text',
                type: 'text',
                name: 'finder_award_tooltip_text'
            },
            // {
            //     label: 'Logo',
            //     type: 'file',
            //     name: 'logo'
            // },
            // {
            //     label: 'Brand title',
            //     placeholder: 'Enter brand title',
            //     type: 'text',
            //     name: 'brand_title'
            // },
            // {
            //     label: 'Brand Link',
            //     placeholder: 'Enter brand url',
            //     type: 'text',
            //     name: 'brand_link'
            // },
        ]
    },

    {
        schema: add_form_step_1_2_schema,
        fields: [
            {
                id: 1,
                formFields: [
                    {
                        label: 'Logo',
                        type: 'file',
                        name: 'brand_logo'
                    },
                    {
                        label: 'Brand title',
                        placeholder: 'Enter brand title',
                        type: 'text',
                        name: 'brand_title'
                    },
                    {
                        label: 'Brand Link',
                        placeholder: 'Enter brand url',
                        type: 'text',
                        name: 'brand_link'
                    },
                ]
            }
        ]
    },

    {
        schema: add_form_step_2_schema,
        fields: [
            {
                label: 'Description',
                placeholder: 'Enter description',
                type: 'text',
                name: 'description'
            },
            {
                label: 'Description background color',
                type: 'color',
                name: 'description_back_color'
            },
            {
                label: 'Description text color',
                type: 'color',
                name: 'description_text_color'
            },
            {
                label: 'Terms and Conditions apply',
                type: 'checkbox',
                name: 't_and_c_apply'
            },
            {
                label: 'Button color',
                type: 'color',
                name: 'button_color'
            },
            {
                label: 'Button text color',
                type: 'color',
                name: 'button_text_color'
            },
            {
                label: 'Button text',
                type: 'text',
                name: 'button_text',
                placeholder: 'Enter description',
            },
            {
                label: 'Review link',
                placeholder: 'Enter review url',
                type: 'text',
                name: 'read_review_link'
            },
            {
                label: 'Review link button text',
                placeholder: 'Enter review link button text',
                type: 'text',
                name: 'read_review_button_text'
            },
            {
                label: 'Review link button text color',
                type: 'color',
                name: 'read_review_button_text_color'
            },
        ]
    }
]

export const login_form = [
    {
        label: 'Phone Number',
        placeholder: 'Enter phone number',
        type: 'text',
        name: 'phone_number'
    },
    {
        label: 'OTP',
        placeholder: 'Enter OTP',
        type: 'text',
        name: 'otp'
    },
]
export const login_signup_form_with_email_and_pass = [
    {
        label: 'Email',
        placeholder: 'Enter email',
        type: 'text',
        name: 'email'
    },
    {
        label: 'Password',
        placeholder: 'Enter password',
        type: 'text',
        name: 'otp'
    },
]