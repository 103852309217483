import { TextField } from '@mui/material'
import React, { useEffect } from 'react'

const TextInput = ({
    label,
    placeholder,
    name,
    error,
    setFieldValue,
    value,
    setFormValues
}) => {
    const handleChange = (e) => {
        setFieldValue(name, e.target.value);
        setFormValues([name], e.target.value)
    }
    useEffect(() => {
        setFieldValue(name, value);
        setFormValues([name], value)
    }, [])

    return (
        <TextField
            error={error}
            sx={{
                width: '100%',
                mt: '20px'
            }}
            defaultValue={value}
            value={value}
            onChange={handleChange}
            InputProps={{
                style: {
                    padding: 0,
                }
            }}
            label={label}
            placeholder={placeholder}
            InputLabelProps={{ shrink: !!value }}
        />
    )
}

export default TextInput