import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web
import { combineReducers } from 'redux';
import dataSlice from './slices/dataSlice';
import formSlice from './slices/formSlice';
import authSlice from './slices/authSlice';
import brandsFieldSlice from './slices/brandsFromStepSlice';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'], // specify which reducers you want to persist
};

const persistedReducer = persistReducer(
    persistConfig,
    combineReducers({
        form: formSlice,
        data: dataSlice,
        auth: authSlice,
        brandsFields: brandsFieldSlice
    })
);

const store = configureStore({
    reducer: persistedReducer,
});

const persistor = persistStore(store);

export { store, persistor };