import { Button, Container, Typography, Box, Tooltip } from '@mui/material'
import Grid from '@mui/material/Unstable_Grid2';
import React, { useCallback, useState } from 'react'
import { add_unit_form } from '../../utils/form'
import { MdOutlinePaid } from "react-icons/md";
import { IoTrophy } from "react-icons/io5";
import { FaTag } from "react-icons/fa6";
import Form from '../../components/Form';
import {
    brand_title_style,
    desc_box_style,
    finder_award_inner_box_style,
    finder_award_outer_box_style,
    logo_image_box_style,
    sponsored_triangle_style,
    sponsored_triangle_text_style,
    title_box_style,
    title_text_style,
    unit_preview_inner_wrapper_style,
    unit_preview_outer_wrapper_style
} from './add_unit_style';
import { GrLinkNext, GrLinkPrevious } from "react-icons/gr";

const AddUnit = () => {
    const [logoPreview, setLogoPreview] = useState(null)
    const [currentStep, setCurrentStep] = useState(1)
    const [formValues, setFormValues] = useState(null)
    const [activeBrand, setActiveBrand] = useState(null)
    const handleSetFormValues = useCallback(
        (key, value) => {
            if (key === null) {
                setFormValues(value)
            }
            else {
                setFormValues(ps => ({
                    ...ps,
                    [key]: value
                }))
            }
        },
        [formValues],
    )

    const handleClickBack = () => {

    }
    const handleClickNext = () => {

    }
    const handleClickSlide = (slideType, index) => {
        if (!formValues?.brands || index < 0 || index >= formValues.brands.length) return;

        const newIndex = slideType === 'prev' ? index - 1 : index + 1;

        if (newIndex >= 0 && newIndex < formValues.brands.length) {
            setActiveBrand(formValues.brands[newIndex].id);
        }
    };


    return (
        <Container sx={{
            my: '20px'
        }}>
            <Grid container gap="15px">
                <Grid item xs={12} md={6.5}>
                    <Typography variant='h3'>Add New Unit</Typography>
                    {add_unit_form?.map(({ fields, schema }, stepIndex) => {
                        if (stepIndex + 1 === currentStep) {
                            return (
                                <Form
                                    isLastStep={stepIndex + 1 === add_unit_form.length}
                                    fields={fields}
                                    schema={schema}
                                    setFormValues={handleSetFormValues}
                                    setCurrentStep={setCurrentStep}
                                    currentStep={currentStep}
                                    setLogoPreview={setLogoPreview}
                                    key={stepIndex}
                                    formValues={formValues}
                                    setActiveBrand={setActiveBrand}
                                />
                            )
                        }
                    })}
                </Grid>
                <Grid item xs={12} md={5}>
                    <Typography variant='h3'>Unit Preview</Typography>
                    <Box sx={unit_preview_outer_wrapper_style}>
                        <Grid container>
                            <Grid item sx={11} md={8} mdOffset={2}>
                                <Box sx={unit_preview_inner_wrapper_style}>
                                    {formValues?.['sponsored'] &&
                                        (
                                            <Box sx={{
                                                ...sponsored_triangle_style,
                                                borderBottom: `35px solid ${formValues['spon_back_background_color']}`,
                                            }}>
                                                <Box sx={sponsored_triangle_text_style}><MdOutlinePaid color="#fff" /></Box>
                                            </Box>
                                        )
                                    }
                                    <Box
                                        sx={{
                                            ...title_box_style,
                                            background: formValues?.['title_background_color']
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                color: formValues?.['title_text_color'],
                                                ...title_text_style
                                            }}
                                        >
                                            {formValues?.['title']}
                                        </Typography>
                                    </Box>
                                    <Box
                                        sx={finder_award_outer_box_style}
                                    >
                                        {
                                            formValues?.['finder_award'] && (
                                                <Tooltip
                                                    title={formValues["finder_award_tooltip_text"]}
                                                >
                                                    <Box sx={finder_award_inner_box_style}
                                                    >
                                                        <IoTrophy />
                                                        <Typography
                                                            sx={{
                                                                ml: '5px'
                                                            }}
                                                        >
                                                            {formValues["finder_award_text"]}
                                                        </Typography>
                                                    </Box>
                                                </Tooltip>
                                            )
                                        }
                                    </Box>
                                    {/* <Box sx={{
                                        minHeight: '250px',
                                    }}>
                                        <Box
                                            sx={logo_image_box_style}
                                        >
                                            {formValues?.['logo'] && (
                                                <Box
                                                    component='img'
                                                    src={logoPreview}
                                                    sx={{
                                                        width: '100px',
                                                        height: '100px'
                                                    }}
                                                    alt='Logo'
                                                />
                                            )}
                                        </Box>
                                        <Box
                                            component={'a'}
                                            href={formValues?.['brand_link']}
                                            sx={brand_title_style}
                                            target='_blank'
                                        >
                                            <Typography sx={{
                                                fontSize: '18px',
                                                fontWeight: 600,
                                            }}>
                                                {formValues?.['brand_title']}
                                            </Typography>
                                        </Box>
                                    </Box> */}
                                    {!!formValues?.['brands']?.length && <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                        {formValues?.['brands'].map((item, i) => {
                                            if (item.id === activeBrand) return (
                                                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', position: 'relative', minHeight: '150px', width: '150px' }}>
                                                    <Box
                                                        component={'img'}
                                                        src={item.brand_logo_preview}
                                                        alt={item?.brand_title}
                                                        sx={{ width: '80px', height: '80px' }}
                                                    />
                                                    <Box
                                                        component={'a'}
                                                        href={item?.brand_link}
                                                        sx={brand_title_style}
                                                        target='_blank'
                                                    >
                                                        <Typography sx={{
                                                            fontSize: '18px',
                                                            fontWeight: 600,
                                                        }}>
                                                            {item?.brand_title}
                                                        </Typography>
                                                    </Box>
                                                    <Box sx={{
                                                        position: 'absolute',
                                                        // background: '#fff',
                                                        top: '50%',
                                                        display: 'flex',
                                                        justifyContent: 'space-between',
                                                        width: '100%',
                                                    }}>
                                                        <GrLinkPrevious style={{ cursor: 'pointer' }} onClick={() => handleClickSlide('prev', i)} />
                                                        <GrLinkNext style={{ cursor: 'pointer' }} onClick={() => handleClickSlide('next', i)} />
                                                    </Box>
                                                </Box>
                                            )
                                            return null
                                        })}
                                    </Box>}
                                    <Box
                                        sx={{
                                            minHeight: '50px',
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        {
                                            formValues?.['description'] &&
                                            <Box
                                                sx={{
                                                    ...desc_box_style,
                                                    backgroundColor: formValues['description_back_color'],

                                                }}
                                            >
                                                <FaTag size={20} color={formValues['description_text_color']} />
                                                <Typography sx={{
                                                    ml: '10px',
                                                    fontSize: '15px',
                                                    color: formValues['description_text_color']
                                                }}>
                                                    {formValues?.['description']}
                                                </Typography>
                                            </Box>
                                        }
                                    </Box>
                                    <Box
                                        sx={{
                                            minHeight: '50px',
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        {
                                            formValues?.['t_and_c_apply'] &&
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    color: '#888'
                                                }}
                                            >
                                                <Typography>T&Cs apply</Typography>
                                            </Box>
                                        }
                                    </Box>
                                    <Box
                                        sx={{
                                            minHeight: '90px',
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        {
                                            formValues?.['button_text'] &&
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Button sx={{
                                                    background: formValues?.['button_color'],
                                                    color: formValues?.['button_text_color'],
                                                    borderRadius: '50px',
                                                    padding: '15px 25px'
                                                }}
                                                    variant='contained'
                                                >
                                                    {formValues?.['button_text']}
                                                </Button>
                                            </Box>
                                        }
                                    </Box>
                                    <Box
                                        sx={{
                                            minHeight: '50px',
                                            display: 'flex',
                                            justifyContent: 'center'
                                        }}
                                    >
                                        {
                                            formValues?.['read_review_button_text'] &&
                                            <Box
                                                sx={{
                                                    display: 'flex',
                                                    justifyContent: 'center',
                                                    alignItems: 'center'
                                                }}
                                            >
                                                <Box
                                                    component={'a'}
                                                    href={formValues?.['read_review_link']}
                                                    target={'_blank'}
                                                    sx={{
                                                        color: formValues?.['read_review_button_text_color'],
                                                    }}

                                                >
                                                    {formValues?.['read_review_button_text']}
                                                </Box>
                                            </Box>
                                        }
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    )
}

export default AddUnit