import { createSlice } from '@reduxjs/toolkit';

const formSlice = createSlice({
    name: 'formSlice',
    initialState: {
        formData: {},
    },
    reducers: {
        setFormData: (state, action) => {
            state.formData = {
                ...state.formData,
                ...action.payload
            };
        },
        clearFormData: (state) => {
            state.formData = {};
        },
    },
});

export const { setFormData, clearFormData } =
    formSlice.actions;
export default formSlice.reducer;
