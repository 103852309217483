import { Box, Input } from '@mui/material'
import React, { useState } from 'react'

const LogoInput = ({
    label,
    placeholder,
    name,
    error,
    setFieldValue,
    value,
    // setLogoPreview,
    setFormValues,
    formValues,
    item
    // setFile
}) => {
    const { brands } = formValues
    // console.log({ item, brands, formValues })
    const [logoPreview, setLogoPreview] = useState(null)
    const handleChange = event => {
        // const tempFormVals = {...formValues}
        const tempBrands = [...brands]
        const brandIndex = tempBrands.findIndex((el) => el.id === item.id)
        const file = event.target.files[0]
        tempBrands[brandIndex].brand_logo = file
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                setLogoPreview(reader.result)
                tempBrands[brandIndex].brand_logo_preview = reader.result

                setFormValues('brands', tempBrands)
            }
            reader.readAsDataURL(file)
        }
    }
    return (
        <Box flex={1} sx={{
            border: 'solid 1px #ccc',
            p: '5px'
        }}
            display={'flex'} justifyContent="center" alignItems={"center"}
        >
            <label htmlFor={item.id}>
                {item?.brand_logo_preview ? <img src={item?.brand_logo_preview} style={{ width: '20px', height: '20px' }} /> : 'Insert Logo'}
            </label>
            <Input
                sx={{
                    display: 'none'
                }}
                id={item.id}
                type="file"
                onChange={handleChange}
            />
        </Box>
    )
}

export default LogoInput