import React, { useEffect, useState } from 'react'
import { Box, Button, Container, Modal, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import { SiBinance } from "react-icons/si";
import { IoClose } from "react-icons/io5";
import { IoMdCheckmark } from "react-icons/io";
import { useNavigate } from 'react-router-dom'
import { supabase } from '../utils/supabase';
import { useDispatch, useSelector } from 'react-redux';
import { getCardsData, deleteCard } from '../store/slices/dataSlice';
import { isTokenExpired } from '../utils/checkAuthorization';
import { logout } from '../store/slices/authSlice';
import { setFormData } from '../store/slices/formSlice';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';


const Home = () => {
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
    const [deletingItemId, setDeletingItemId] = useState(null)
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { cards } = useSelector((state => state?.data))
    const { userData } = useSelector((state) => state?.auth)
    const [openDeleteToastSuccess, setOpenDeleteToastSuccess] = useState(false);
    const [openDeleteToastFailed, setOpenDeleteToastFaied] = useState(false);

    useEffect(() => {
        if (isTokenExpired(userData)) {
            supabase.auth.signOut().then(() => {
                dispatch(logout())
            })
        }
        else {
            const { data, error } = supabase.from('ads').select('*').then((rs) => {
                // console.log({ rs })
                dispatch(getCardsData(rs.data))
            })

        }
    }, [])
    const handleDeleteAd = async () => {
        try {
            const { data, error } = await supabase
                .from('ads')
                .delete()
                .match({ 'id': deletingItemId });
            if (error) {
                handleOpenFailedToast()
                throw error;
            }
            handleOpenSuccessToast()
            dispatch(deleteCard(deletingItemId))
            setDeletingItemId(null);
            setIsOpenDeleteModal(false)
            // If deletion is successful, refetch the data
            // fetchData();
        } catch (error) {
            console.error('Error deleting data:', error.message);
        }
    };

    const handleOpenSuccessToast = () => {
        setOpenDeleteToastSuccess(true);
    };

    const handleCloseSuccessToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDeleteToastSuccess(false);
    };
    const handleOpenFailedToast = () => {
        setOpenDeleteToastFaied(true);
    };

    const handleCloseFailedToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpenDeleteToastFaied(false);
    };

    return (
        <Container maxWidth="lg">
            <Box sx={{
                my: '20px'
            }}><Button variant='contained' onClick={() => navigate('add-unit')}>Create Ad Unit</Button></Box>
            <Box sx={{ display: 'flex', background: "#ccc" }} >
                <Box sx={{ flex: 1 }}><Typography>S.No</Typography></Box>
                {/* <Box sx={{ flex: 1 }}><Typography>Icon</Typography></Box> */}
                <Box sx={{ flex: 1 }}><Typography>Title</Typography></Box>
                <Box sx={{ flex: 1 }}><Typography>Description</Typography></Box>
                <Box sx={{ flex: 1 }}><Typography>Sponsored</Typography></Box>
                <Box sx={{ flex: 1 }}><Typography>Action</Typography></Box>
            </Box>
            {
                // cards.map(({ logo, title, description, sponsored, id }, index) => (
                cards.map((item, index) => (
                    <Box sx={{ display: 'flex', mt: '10px', borderBottom: 'solid 1px #ccc' }} flex key={index}>
                        <Box sx={{ flex: 1 }}><Typography>{index + 1}</Typography></Box>
                        {/* <Box sx={{ flex: 1 }}><Box component="img" alt="Logo" src={item?.logo} sx={{ width: '20px', height: '20px' }} /></Box> */}
                        <Box sx={{ flex: 1 }}><Typography>{item?.title}</Typography></Box>
                        <Box sx={{ flex: 1 }}><Typography>{item?.description}</Typography></Box>
                        <Box sx={{ flex: 1 }}>{item?.sponsored ? <IoMdCheckmark />
                            : <IoClose />}</Box>
                        <Box sx={{ flex: 1, display: 'flex' }}>
                            <EditOutlinedIcon onClick={() => {
                                dispatch(setFormData({
                                    ...item
                                }))
                                navigate(`update-unit/${item.id}`)
                            }} />
                            {/* <DeleteOutlineOutlinedIcon onClick={() => handleDeleteAd(id)} /> */}
                            <DeleteOutlineOutlinedIcon onClick={() => {
                                setDeletingItemId(item?.id)
                                setIsOpenDeleteModal(true)
                            }} />
                        </Box>
                    </Box>
                ))
            }

            <Box sx={{ my: '20px', }}>
                <Typography sx={{ fontSize: "20px" }}>Embeded code</Typography>
                <Box component={'textarea'} sx={{
                    mt: '10px',
                    borderRadius: '5px',
                    width: '50%',
                    resize: 'none',
                    p: '20px'
                }}
                    disabled
                    rows={5}
                >
                    {`<iframe src='https://admanager.agrtech.com.au/api/supabase/supa-data' width="700px" height="650px" />`}
                </Box>
            </Box>
            <Modal
                open={isOpenDeleteModal}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}
                onClose={() => {
                    setDeletingItemId(null);
                    setIsOpenDeleteModal(false)
                }}
            >
                {/* <Box sx={{ width: "400px", background: "#fff" }}><h1>{deletingItemId}</h1></Box> */}
                <Box sx={{ width: "400px", background: "#fff", p: '15px', borderRadius: '10px' }}>
                    <Typography sx={{
                        textAlign: 'center',
                        mb: '10px'
                    }}>Are you Sure you want to delete ad unit? Action won't be reversable!</Typography>
                    <Box display={'flex'} justifyContent='center' alignItems={'center'}>
                        <Button variant='outlined' sx={{
                            mr: '10px'
                        }}
                            onClick={() => {
                                setDeletingItemId(null);
                                setIsOpenDeleteModal(false)
                            }}>Cancel</Button>
                        <Button onClick={handleDeleteAd} variant='contained'>Delete</Button>
                    </Box>
                </Box>
            </Modal>
            <Snackbar open={openDeleteToastSuccess} autoHideDuration={6000} onClose={handleCloseSuccessToast}>
                <Alert
                    onClose={handleCloseSuccessToast}
                    severitFailed="success"
                    variant="filled"
                    sx={{ width: '100%' }}
                >
                    Item deleted successfully
                </Alert>
            </Snackbar>
            <Snackbar open={openDeleteToastFailed} autoHideDuration={6000} onClose={handleCloseFailedToast}>
                <Alert
                    onClose={handleCloseFailedToast}
                    severitFailed="error"
                    variant="filled"
                    sx={{ width: '100%', bgcolor: 'red' }}
                >
                    Failed to delete item
                </Alert>
            </Snackbar>
        </Container>
    )
}

export default Home