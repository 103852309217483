import { Input } from '@mui/material';
import React from 'react'

const FileInput = ({
    label,
    placeholder,
    name,
    error,
    setFieldValue,
    value,
    setLogoPreview,
    setFormValues,
    // setFile
}) => {
    const handleChange = (event) => {
        const file = event.target.files[0];
        // setFile(file)
        setFieldValue(name, file);
        setFormValues([name], file)
        // setFormValues(ps => ({
        //     ...ps,
        //     [name]: file
        // }))
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoPreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };
    // const handleChange = (e) => {
    //     const file = e.target.files[0]
    //     setFieldValue(name, file);
    // }
    return (
        <Input
            error={error}
            sx={{
                width: '100%',
                mt: '20px',
                '&:hover': {
                    '&::before': {
                        borderBottom: 'none !important',
                    }
                },
                '&Mui-focused': {
                    outline: 'none',
                },
                '&::before': {
                    borderBottom: 'none'
                }
            }}
            type="file"

            onChange={handleChange}
            InputProps={{
                style: {
                    padding: 0,
                }
            }}
            label={label}
            placeholder={placeholder}
        />
    )
}

export default FileInput